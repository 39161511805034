<template>
  <div class="settings-container">
    <setting-nav></setting-nav>
    <div class="content-container">

      <!-- 1 seni taniyalim-->
      <section id="sectionID_1" class="section">
        <div class="section-header-body" style="margin-top: 0px;">
          <h3 class="section-header">Seni <span>Tanıyalım</span></h3>
          <div class="edit-area" @click="editAreaClick(1)" v-if="editableSectionId !== 1">
            <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
            <p class="edit-text">Düzenle</p>
          </div>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[0]">
            <loader/>
          </div>
          <input id="selectedFile" accept="image/*" type="file" style="display: none;" @change="sendImageToCropModal"/>

          <!--not editable area-->
          <div class="user-info-section" v-if="editableSectionId !== 1">
            <div class="user-avatar">
              <img :src="$store.state.user.avatar_url" alt="user avatar" class="user-avatar-img">
            </div>
            <div class="user-info">
              <p class="username-text">{{userInfo.username}}</p>
              <p class="user-fistname-lastname-text">{{userInfo.firstname + ' ' + userInfo.lastname}}</p>
              <div class="separator"></div>
              <p class="profession-text">{{userInfo.title ? userInfo.title : 'Uzmanlık Alanın Nedir?'}}</p>
              <div class="profession-description-area">
                <p class="profession-description">
                  {{userInfo.brief && userInfo.brief.length ? userInfo.brief :
                  user.seller.isSeller ?
                  'Alıcıların seni ve yaptığın işleri daha yakından tanıması için yazacağın birkaç satırlık tanıtım yazısı, daha kolay teklif alman için faydalı olacaktır.'
                  :
                  'Freelancer’ların seni tanıması için yazacağın birkaç satırlık tanıtım yazısı, açmış olduğun özel alıcı isteklerine daha kolay teklif alman ve talep ettiğin hizmeti en hızlı biçimde tedarik etmen için faydalı olacaktır.'}}
                </p>
              </div>
            </div>
          </div>

          <!--editable area-->
          <div class="user-info-section" v-else>
            <div class="user-avatar">
              <img :src="tempUserInfo.avatar_url" alt="user avatar" class="user-avatar-img">
              <div class="add-image" @click="setImage()">
                <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="avatar-add-img">
              </div>
            </div>
            <div class="user-info">
              <div style="display: flex;margin-bottom: 30px; align-items: center">
                <p v-if="!isUsernameEditable" class="username-text" style="margin-bottom: 27px;">{{user.username}}</p>
                <custom-input
                  v-else
                  v-model="tempUserInfo.username"
                  :valid="null"
                  style="width: 191px;"
                />
                <p v-if="!isUsernameEditable" @click="isUsernameEditable = true" style="color: #fd4056; margin-bottom: 20px; font-size: 14px; margin-left: 10px; cursor: pointer;">[değiştir]</p>

                <custom-button v-if="isUsernameEditable" buttonType="green" :disabled="changeUsernameLoading" style="font-size:14px;width: 80px; padding-bottom: 2px; height: 30px; margin-left: 30px" @click="changeUsername(tempUserInfo.username)">Değiştir</custom-button>
                <custom-button v-if="isUsernameEditable" buttonType="border" style="font-size:14px;width: 80px; padding-bottom: 2px; height: 30px; margin-left: 10px" @click="isUsernameEditable = false; tempUserInfo.username = user.username">Vazgeç</custom-button>
                <div v-if="isUsernameEditable && pendingUsernameMessage" style="position: relative; margin-left: 10px">
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>
                  <div class="tooltiptext" style="visibility: visible;">
                    <help-box
                      :description="pendingUsernameMessage"></help-box>
                  </div>
                </div>
              </div>

              <div style="display: flex; margin-bottom: 42px;">
                <custom-input label="Ad"
                              inputType="text"
                              v-model="tempUserInfo.firstname"
                              :valid="tempUserInfo.firstname !== userInfo.firstname ? true : null"
                              inputStyle="text-transform: capitalize;"
                              style="width: 191px;"/>

                <custom-input label="Soyad"
                              inputType="text"
                              v-model="tempUserInfo.lastname"
                              :valid="tempUserInfo.lastname !== userInfo.lastname ? true : null"
                              inputStyle="text-transform: capitalize;"
                              style="margin-left: 11px; width: 191px;"/>

              </div>
              <custom-select label="Uzmanlık Kategorisi"
                             v-if="user.seller.isSeller"
                             id="professionCategory"
                             :options="professionCategoryArr"
                             :customOption="{name: 'name', value:'value'}"
                             v-model="tempSellerInfo.professionCategory"
                             :valid="tempSellerInfo.professionCategory !== sellerInfo.professionCategory ? true : null"
                             style="width: 393px; margin-bottom: 42px;"/>

              <custom-input label="Başlık"
                            place-holder="Örn: Tasarımcı"
                            inputType="textArea"
                            v-model="tempUserInfo.title"
                            :valid="tempUserInfo.title !== userInfo.title ? true : null"
                            maxlength="60"
                            style="margin-bottom: 40px; height: 48px; width: 393px;"/>
              <custom-input label="Hakkında"
                            inputType="textArea"
                            v-model="tempUserInfo.brief"
                            :valid="tempUserInfo.brief !== userInfo.brief ? true : null"
                            maxlength="600"
                            style="width:658px; height: 200px;"/>
            </div>
          </div>

          <div class="separator" style="margin-top: 30px;" v-if="editableSectionId === 1"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 1">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(1)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(1)" @click="saveButton(1)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <!-- 2 iletisim ayarlari-->
      <section  id="sectionID_2" class="section">
        <div class="section-header-body">
          <h3 class="section-header">İletişim <span>Ayarları</span></h3>
          <div class="edit-area" @click="editAreaClick(2)" v-if="editableSectionId !== 2">
            <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
            <p class="edit-text">Düzenle</p>
          </div>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[1]">
            <loader/>
          </div>

          <!--not editable area-->
          <div class="contact-info" v-if="editableSectionId !== 2">
            <div class="contact-item">
              <label class="contact-label left">E-posta Adresi</label>
              <p class="contact-text right">{{userInfo.email}}</p>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
            <div class="contact-item">
              <label class="contact-label left">Cep Numarası</label>

              <div class="gsm right" v-if="userInfo.gsm">
                <p class="contact-text">{{userInfo.gsm}}</p>
                <div class="gsm-active" v-if="user.info.gsm_is_activated">
                  <img src="https://gcdn.bionluk.com/site/c/ic-verified-badge.svg" class="gsm-active-img" onload="SVGInject(this)"/>
                  Onaylı
                </div>
                <div class="gsm-inactive" v-if="!user.info.gsm_is_activated">Onaylı Değil</div>
                <button class="add-dash-button" v-if="!user.info.gsm_is_activated" style="margin-left: 20px;" :disabled="disableVerify" @click="sendVerificationNumber()">
                  <img src="https://gcdn.bionluk.com/site/c/ic-phone.svg" style="width: 14px; height: 14px; opacity: .5; margin-right: 5px; margin-bottom: -2px;">
                  <p>Numarayı Onayla</p>
                </button>
              </div>
              <button class="add-dash-button" @click="editAreaClick(2)" v-else>
                <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="add-icon"/>
                Ekle
              </button>
            </div>
            <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" v-if="user.seller.isSeller"></div>
            <div class="contact-item" v-if="user.seller.isSeller">
              <label class="contact-label left" style="align-self: flex-start;">Freelancer Hattı<img @click="openFreelancerLineFaqModal()" src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" alt="help" width="20" height="20" style="margin-left: 5px; cursor: pointer; margin-bottom: -4px;">
              </label>
              <!--freelance hatti olan-->
              <div class="freelancer-gsm right" v-if="user.info.gsm_is_activated && user.info.f_t_used && user.info.f_t_ending_at && user.info.f_t_status !== 1">
                <div class="freelancer-gsm-item">
                  <p class="freelancer-gsm-item-label">Durum</p>
                  <p class="freelancer-gsm-item-label" style="width: unset; margin-right: 10px;">:</p>
                  <div class="f-t-status-badge-passive" v-if="user.info.f_t_status === 2 || user.info.f_t_status === 4">Aktif Değil</div>
                  <div class="f-t-status-badge-active" v-if="user.info.f_t_status === 3">Aktif</div>
                </div>
                <div class="freelancer-gsm-item" style="margin-top: 20px;">
                  <p class="freelancer-gsm-item-label">Sana Ulaşılacak Telefon</p>
                  <p class="freelancer-gsm-item-label" style="width: unset; margin-right: 10px;">:</p>
                  <p class="freelancer-gsm-item-text" style="font-weight: bold;">0 (212) 909 - 23 - 10</p>
                  <div data-balloon="Sana ulaşmak isteyen kullanıcılara bu telefon numarasını verebilirsin. Kullanıcılar bu numarayı aradıktan sonra alttaki dahili numaran ile üst bölümdeki onaylı cep telefon numarası üzerinden sana ulaşabilirler."
                       data-balloon-pos="up"
                       data-balloon-length="medium"
                       style="display: inline-flex; cursor: help; margin-left: 5px;">
                    <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" width="16px" height="16px">
                  </div>
                </div>
                <div class="freelancer-gsm-item" style="margin-top: 20px;">
                  <p class="freelancer-gsm-item-label">Dahili Numaran</p>
                  <p class="freelancer-gsm-item-label" style="width: unset; margin-right: 10px;">:</p>
                  <p class="freelancer-gsm-item-text" style="font-weight: bold;">{{user.info.f_t_line}}</p>
                </div>
                <div class="freelancer-gsm-item" style="margin-top: 20px;">
                  <p class="freelancer-gsm-item-label">Geçerlilik Tarihi</p>
                  <p class="freelancer-gsm-item-label" style="width: unset; margin-right: 10px;">:</p>
                  <p class="freelancer-gsm-item-text"> {{user.info.f_t_status === 4 ? 'Hattın Süresi Doldu' : endingDate}}<span @click="extend()" style="color: #fd4056; font-size: 14px; margin-left: 10px; cursor: pointer;">[süreyi uzat]</span></p>
                </div>
                <div class="freelancer-gsm-item" style="margin-top: 20px;" v-if="user.info.f_t_status !== 4">
                  <p class="freelancer-gsm-item-label">Nerede Gösterilsin?</p>
                  <p class="freelancer-gsm-item-label" style="width: unset; margin-right: 10px;">:</p>
                  <p class="freelancer-gsm-item-text" v-if="user.info.f_t_mode === 2">Gösterme<span style="color: #8b95a1; font-size: 14px; margin-left: 5px;">(sadece ben verebileyim)</span></p>
                  <p class="freelancer-gsm-item-text" v-if="user.info.f_t_mode === 1">İlan ve Profil Sayfalarında Göster</p>
                  <div data-balloon="Her yerde göster seçeneğini seçmen durumunda sana özel dahili numaran ilan ve profil sayfalarında gösterilir. "
                       data-balloon-pos="up"
                       data-balloon-length="medium"
                       style="display: inline-flex; cursor: help; margin-left: 5px;">
                    <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" width="16px" height="16px">
                  </div>
                </div>

                <p class="freelancer-gsm-text" style="margin-top: 16px;" v-if="user.info.f_t_status === 4">
                  Tüm Freelancer’lar için
                  <span class="green">İlk 30 Gün ücretsiz,</span>
                  olan
                  <span class="dark">Bionluk Freelancer Hattı,</span>
                  limitsiz konuşma ve yönlendirme için sadece aylık
                  <span class="green">12.90TL</span>
                </p>
              </div>

              <!--numara aktif, hatti yok-->
              <div class="freelancer-gsm right" v-else-if="!user.info.f_t_used && user.info.gsm_is_activated">
                <p class="freelancer-gsm-text">
                  <span class="dark">Bionluk Freelancer Hattı</span>
                  sayesinde kişisel numaranı paylaşmaksızın cep telefonun üzerinden tüm Bionluk kullanıcılarıyla güvenle görüşmeye başlayabilirsin. Ayrıntılı bilgi için
                  <router-link to="/destek/freelancer-telefon-hatti" class="green">destek sayfamızı</router-link>
                  ziyaret edebilirsin.
                </p>

                <custom-button buttonType="red" style="width: 300px; margin-top: 20px;" @click="startLine()">Bionluk Freelancer Hattı'mı Aktif Et</custom-button>

                <p class="freelancer-gsm-text" style="margin-top: 20px;">
                  <span class="green">İlk 30 Gün ücretsiz,</span>
                  olan
                  <span class="dark">Bionluk Freelancer Hattı</span>
                  daha sonra limitsiz konuşma ve yönlendirme için sadece aylık
                  <span class="green">12.90TL</span>
                </p>
              </div>

              <!--numara aktif degil-->
              <p class="freelancer-gsm-text right" v-else>Freelancer hattını kullanabilmek için öncelikle üst bölümden cep telefonunu <span class="red">eklemen ve onaylaman</span> gerekiyor.</p>
            </div>
          </div>

          <!--editable area-->
          <div class="contact-info" v-else>
            <div style="display: flex; align-items: flex-start; margin-bottom: 42px; ">
              <custom-input label="E-Posta Adresi"
                            v-model="tempUserInfo.email"
                            :valid="tempUserInfo.email !== userInfo.email ? true : null"
                            style="width: 393px;"/>
              <div class="hint-body">
                <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>
                <p>Bazı <span>İpuçları</span> 😉</p>
                <div class="tooltiptext" style="visibility: visible;">
                  <help-box
                    description="<ul style='margin-left:20px;list-style:disc'><li>E-Posta adresin Bionluk’taki eşsiz kimliğindir. Bu yüzden e-posta adresini değiştirirsen, bir sonraki girişinde <span style='font-weight: 800;'>yeni  e-posta adresini kullanman gerekir.</span></li><li style='margin-top:20px'>E-Posta <span style='font-weight: 800;'>adresin ve cep numaranın</span> hiç kimseyle <span style='font-weight: 800;'>paylaşılmadığını</span> ve hiçbir yerde <span style='font-weight: 800;'>gösterilmediğini</span> hatırlatırız.</li></ul>"></help-box>
                </div>

              </div>
            </div>

            <div style="display: flex;">
              <custom-input label="Cep Numarası"
                            inputType="gsm"
                            placeHolder="Örnek: 543 210 00 00"
                            v-model="tempUserInfo.gsm"
                            :valid="tempUserInfo.gsm !== userInfo.gsm ? true : null"
                            style="width: 393px;"/>
            </div>

            <!--freelance hatti varsa editlenir-->
            <div v-if="user.info.gsm_is_activated && user.info.f_t_used && user.info.f_t_ending_at && user.info.f_t_status !== 1">
              <div class="separator" style="margin-top: 30px; margin-bottom: 30px;" v-if="editableSectionId === 2"></div>
              <label class="contact-label" style="color: #2d3640; font-weight: 800;">Freelancer Hattı
                <img @click="openFreelancerLineFaqModal()" src="https://gcdn.bionluk.com/site/icon/ic_help_v.svg" alt="help" width="20" height="20" style="margin-left: 4px; cursor: pointer; margin-bottom: -4px;">
              </label>

              <div style="display: flex; align-items: center; margin-top: 30px;">
                <div class="change-f_t_line">
                  Dahili Numaran: <span>{{ user.info.f_t_line}}</span>
                </div>
                <custom-button buttonType="dark" style="width: 160px; height: 45px; margin-left: 18px;" @click="changeLine()">Değiştir</custom-button>
              </div>
              <custom-select
                            v-if="user.info.f_t_status === 2 || user.info.f_t_status === 3"
                            label="Hattın Durumu"
                             id="f_t_status"
                             :options="f_t_status_array"
                             :customOption="{name: 'name', value:'value'}"
                             v-model="tempUserInfo.f_t_status"
                             :valid="tempUserInfo.f_t_status !== userInfo.f_t_status ? true : null"
                             style="width: 393px; margin-top: 42px;"/>

              <custom-select label="Gösterim Şekli"
                             id="f_t_mode"
                             :options="f_t_mode_array"
                             :customOption="{name: 'name', value:'value'}"
                             v-model="tempUserInfo.f_t_mode"
                             :valid="tempUserInfo.f_t_mode !== userInfo.f_t_mode ? true : null"
                             style="width: 393px; margin-top: 42px;"/>
            </div>

          </div>

          <div class="separator" :style="user.info.gsm_is_activated ? 'margin-top: 110px;' : 'margin-top: 50px;'" v-if="editableSectionId === 2"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 2" style="margin-top: 40px;">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(2)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(2)" @click="saveButton(2)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <!-- 3 Uzmanı Olduğun Alanlar & Araçlar-->
      <section id="sectionID_3" class="section" v-if="user.seller.isSeller">
        <div class="section-header-body">
          <h3 class="section-header">Uzmanı Olduğun <span> Alanlar & Araçlar</span></h3>
          <div class="edit-area" @click="editAreaClick(3)" v-if="editableSectionId !== 3 && sellerInfo.skills && sellerInfo.skills.length">
            <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
            <p class="edit-text">Düzenle</p>
          </div>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[2]">
            <loader/>
          </div>

          <!--not editable area-->
          <div class="skill-body" v-if="editableSectionId !== 3">
            <div class="skill-item-body" v-if="sellerInfo.skills && sellerInfo.skills.length">
              <div :class="skill.isSuggestion ? 'skill-item-sugessted' : 'skill-item'" v-for="(skill, index) in sellerInfo.skills" :key="index">
                <img src="https://gcdn.bionluk.com/site/c/ic-timer.svg" onload="SVGInject(this)" class="suggestion-skill-icon" v-if="skill.isSuggestion">
                {{skill.name}} <span v-if="skill.isSuggestion">[Senin Önerin]</span>
              </div>
            </div>

            <p class="freelancer-gsm-text" v-else>Uzmanı olduğun alan ve araçları eklemen alıcıların tercihte bulunmaları ve daha kolay teklif alman için faydalı olacaktır.
              <span class="green">[En fazla 6 adet yetenek ekleyebilirsin.]</span>
            </p>
            <button v-if="sellerInfo.skills && !sellerInfo.skills.length" class="add-dash-button" style="margin-left: 34px;" @click="editAreaClick(3)">
              <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="add-icon"/>
              Ekle
            </button>
          </div>

          <!--editable area-->
          <div v-else>
            <div style="display: flex; align-items: center; margin-top: 16px;">
              <custom-select label="Yetenekler"
                             placeHolder="Örnek: Adobe XD veya Grafik Tasarımı"
                             id="skills"
                             inputType="search"
                             :disabled="disableSkillInput"
                             :options="skillSearchResults"
                             :exactMatchExists="exactSkillExists"
                             :customOption="{name: 'name', value:'id'}"
                             :suggestion-enabled="true"
                             v-model="skillSearchTerm"
                             @change="selectBoxOnChange"
                             @focus="openSkillTooltip = false"
                             style="width: 393px;"/>
              <div class="hint-body">
                <img src="https://gcdn.bionluk.com/site/icon/tansel_info_gray.svg" onload="SVGInject(this)" class="hint-skill-icon"/>
                <p>Bazı <span>İpuçları</span> 😉</p>
                <div @click="openSkillTooltip = false" class="tooltiptext" :style="openSkillTooltip ? 'visibility: visible;' : ''">
                  <help-box
                    description="<ul style='margin-left:20px;list-style:disc'><li>En fazla <span style='font-weight: 800;'>6 adet</span> yetenek ekleyebilirsin.</li><li style='margin-top:20px'>Eğer yeni bir öneride bulunursan, uygunluğu incelenecek ve daha sonra yayınlanacak.</li></ul>"></help-box>
                </div>

              </div>

            </div>

            <div class="editable-skills-body">


              <div v-for="(skill, index) in tempSellerInfo.skills" :key="skill.id">
                <div class="suggestion-skill-item" v-if="skill.isSuggestion"
                     data-balloon="Öneride bulunduğun için teşekkürler. 🤓 Önerdiğin yetenek ilk fırsatta incelenecek ve eğer kabul görürse profil sayfanda görüntülenmeye başlayacak."
                     data-balloon-pos="up"
                     data-balloon-length="medium" @click="deleteSkill(skill)">
                  <img src="https://gcdn.bionluk.com/site/c/ic-timer.svg" onload="SVGInject(this)" class="suggestion-skill-icon">
                  {{skill.name}} <span>[Senin Önerin]</span>
                  <img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" onload="SVGInject(this)" class="delete-skill-icon"/>
                </div>

                <div class="editable-skill-item" @click="deleteSkill(skill)" v-else>
                  {{skill.name}}
                  <img src="https://gcdn.bionluk.com/site/icon/ic_close_black.svg" onload="SVGInject(this)" class="delete-skill-icon"/>
                </div>
              </div>
            </div>

          </div>

          <div class="separator" :style="tempSellerInfo.skills && tempSellerInfo.skills.length ? 'margin-top: 100px;' : 'margin-top: 150px;'" v-if="editableSectionId === 3"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 3">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(3)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(3)" @click="saveButton(3)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <!-- 4 Eğitim ve Sertifika Bilgileri-->
      <div id="sectionID_4" class="section-header-body" v-if="user.seller.isSeller">
        <h3 class="section-header">Eğitim ve Sertifika <span> Bilgileri</span></h3>
        <div class="edit-area" @click="editAreaClick(4)" v-if="editableSectionId !== 4 && sellerInfo.educations && sellerInfo.educations.length">
          <p class="edit-text">
            <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="add-icon"/>
            Ekle
          </p>
        </div>
      </div>
      <div class="seller-type-body" v-if="editableSectionId === 4 && !educationEditId && user.seller.isSeller">
        <p class="type-header">Kategori</p>
        <button class="type-radio-body" v-for="(type, index) in educationTypeArr" :key="index" @click="selectEducationType(type.value)" :disabled="false">
          <div class="type-radio-body-button" :style="educationType === type.value ? 'background-color: #00a575; border: none;' : ''">
            <span class="selected-radio-mask" v-if="educationType === type.value"></span>
          </div>
          <p class="type-radio-text" :style="educationType === type.value ? 'font-weight: 500;' : ''">{{type.name}}</p>
        </button>
      </div>
      <section class="section" v-if="user.seller.isSeller">

        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[3]">
            <loader/>
          </div>


          <!--not editable area-->
          <div class="education-info" v-else-if="editableSectionId !== 4">
            <p class="freelancer-gsm-text" style="width: 700px;" v-if="!sellerInfo.educations || !sellerInfo.educations.length">
              Eğitim bilgilerini veya varsa katıldığın sertifika programlarını eklemen alıcıların tercihte bulunmaları ve daha kolay teklif alman için faydalı olabilir.
            </p>
            <button v-if="!sellerInfo.educations || !sellerInfo.educations.length" class="add-dash-button" style="margin-left: 34px;" @click="editAreaClick(4)">
              <img src="https://gcdn.bionluk.com/site/c/ic-add-or-plus.svg" onload="SVGInject(this)" class="add-icon"/>
              Ekle
            </button>
            <div class="education-item" v-for="(edu,index) in sellerInfo.educations" v-else>

              <div style="display: flex; align-items: center">
                <p class="education-school">{{edu.school}},</p>
                <p class="education-department">{{edu.department}},</p>
                <p class="education-endYear">{{edu.endYear}}</p>
              </div>

              <div class="action-body">
                <img @click="educationActions(edu, 'edit')" src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="12" height="12" style="padding: 0 5px; cursor: pointer;"/>
                <img @click="educationActions(edu, 'delete')" src="https://gcdn.bionluk.com/site/icon/ic_delete.svg" width="9" height="12" style="opacity: 0.55; margin-left: 5px; padding: 0 5px; cursor: pointer;"/>
              </div>
            </div>
          </div>

          <!--editable area  education-->
          <div v-else-if="editableSectionId === 4 && educationType === 'education'">
            <custom-input label="Okulunun Adı"
                          inputType="text"
                          v-model="newEducation.school"
                          :valid="editableSectionId ? (newEducation.school !== newEducationValidation.school ? true : null): (newEducation.school.length ? true : null)"
                          style="margin-bottom: 47px; width: 393px;"/>
            <custom-input label="Okuduğun Bölüm"
                          inputType="text"
                          v-model="newEducation.department"
                          :valid="editableSectionId ? (newEducation.department !== newEducationValidation.department ? true : null): (newEducation.department.length ? true : null)"
                          style="margin-bottom: 47px; width: 393px;"/>
            <custom-select label="Mezuniyet Yılı"
                           id="educationYear"
                           :options="educationYearArr"
                           v-model="newEducation.endYear"
                           :valid="editableSectionId ? (newEducation.endYear !== newEducationValidation.endYear ? true : null): (newEducation.endYear ? true : null)"
                           style="width: 166px;"/>
          </div>

          <!--editable area  certificate-->
          <div v-else-if="editableSectionId === 4 && educationType === 'certificate'">
            <custom-input label="Sertifika Aldığın Kurumun Adı"
                          inputType="text"
                          v-model="newEducation.school"
                          :valid="newEducation.school.length ? true : null"
                          style="margin-bottom: 47px; width: 393px;"/>
            <custom-input label="Sertifika Aldığın Konu"
                          inputType="text"
                          v-model="newEducation.department"
                          :valid="newEducation.department.length ? true : null"
                          style="margin-bottom: 47px; width: 393px;"/>
            <custom-select label="Mezuniyet Yılı"
                           id="educationYear"
                           :options="educationYearArr"
                           v-model="newEducation.endYear"
                           :valid="newEducation.endYear ? true : null"
                           style="width: 166px;"/>
          </div>

          <div class="separator" style="margin-top: 50px;" v-if="editableSectionId === 4"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 4">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(4)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(4)" @click="saveButton(4)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <!--Hesap Yönetimi-->
      <section class="section">
        <div class="section-header-body">
          <h3 class="section-header">Hesap <span> Yönetimi</span></h3>
        </div>
        <div class="section-body">

          <!--not editable area-->
          <div v-if="editableSectionId !== 5">
            <button class="add-dash-button" @click="editAreaClick(5)">
              <img src="https://gcdn.bionluk.com/site/c/ic-logout.svg" onload="SVGInject(this)" class="logout-img" style="margin-bottom: -2px;"/>
              Hesabını Askıya Al
            </button>
          </div>

          <!--editable area-->
          <div v-else>
            <p style="color: #8b95a1; font-weight: 400; font-size: 14px; line-height: 26px;">
              Hesabını askıya alırsan Bionluk.com'daki profilin askıya alınır ve paylaşmış olduğun birçok gönderiden isminin ve fotoğrafının görüntülenmemesine sebep olur. Diğer kullanıcıların takip listelerinde ve göndermiş olduğun mesajlarda ise görünür olmaya devam edersin. 
              <br/>
              <br/>
              Eğer freelancer'san hesap bilgilerin ve alışverişlerin sistemde kayıtlı ve gizli tutulur ama portfolyoların ve tüm iş ilanların sistemden kaldırılır.
            </p>
          </div>

          <div class="separator" style="margin-top: 30px;" v-if="editableSectionId === 5"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 5">
            <custom-button buttonType="border" style="width: 100px;" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="dark" style="width: 170px; margin-left: 30px;" @click=" saveButton(5)">Hesabımı Askıya Al</custom-button>
          </div>


        </div>
      </section>
    </div>
    <div class="grey-background"></div>
  </div>
</template>

<script>
  import Fuse from 'fuse.js'
  import Api from "@/utils/request"
  import SettingNav from "../navComponent"
  import CustomInput from "../../../components/CustomInput"
  import CustomButton from "../../../components/CustomButton"
  import CustomSelect from "../../../components/CustomSelect"
  import helpBox from "../../../components/HelpBox"
  import { customDateFormat }  from '@/utils/dateFns'

  export default {
    name: "src-pages-body-settings-mainpage-v2",
    components: {
      SettingNav,
      CustomInput,
      CustomButton,
      CustomSelect,
      helpBox
    },
    data() {
      return {
        editableSectionId: null,
        userInfo: {},
        tempUserInfo: {},

        sellerInfo: {
          skills: [],
        },
        tempSellerInfo: {
          skills: [],
        },

        allSkills: [],
        skillSearchTerm: '',
        skillSearchResults: [],
        exactSkillExists: 0,
        disableSkillInput: false,
        openSkillTooltip: true,

        professionCategoryArr: [
          {
            value: "Consultant",
            name: "Danışman"
          },
          {
            value: "Data Scientist",
            name: "Data Scientist"
          },
          {
            value: "Developer",
            name: "Yazılım Uzmanı"
          },
          {
            value: "Designer",
            name: "Tasarımcı"
          },
          {
            value: "Influencer",
            name: "Influencer"
          },
          {
            value: "Marketer",
            name: "Pazarlama Uzmanı"
          },
          {
            value: "Musician",
            name: "Müzisyen"
          },
          {
            value: "Translator",
            name: "Çevirmen"
          },
          {
            value: "Video Maker",
            name: "Video/Prodüksiyon Uzmanı"
          },
          {
            value: "Virtual Asistant",
            name: "Sanal Asistan"
          },
          {
            value: "Voiceover Artist",
            name: "Seslendirmen"
          },
          {
            value: "Writer",
            name: "Yazar"
          }
        ],
        f_t_status_array: [
          {
            name: 'Aktif',
            value: 3
          },
          {
            name: 'Aktif Değil',
            value: 2
          },
        ],
        f_t_mode_array: [
          {
            name: 'İlan ve Profil Sayfalarında Göster',
            value: 1
          },
          {
            name: 'İlan ve Profil Sayfalarında Gösterme',
            value: 2
          }
        ],

        requestWaiter: [
          true,
          true,
          true,
          true
        ],
        disableVerify: false,

        educationTypeArr: [
          {
            name: 'Eğitim Bilgisi',
            value: 'education'
          },
          {
            name: 'Sertifika Bilgisi',
            value: 'certificate'
          }
        ],
        educationType: 'education',
        educationYearArr: [],
        newEducation: {
          school: '',
          department: '',
          endYear: ''
        },
        newEducationValidation: {
          school: '',
          department: '',
          endYear: ''
        },
        educationEditId: null,
        croppedFile: null,
        fuse: null,
        exactFuse: null,
        isUsernameEditable: false,
        pendingUsernameMessage: null,
        changeUsernameLoading: false
      }
    },
    methods: {
      initializeSeller() {
        this.getAllSkills();
        this.getSeller();
      },
      setInitialValues() {
        this.editableSectionId = null;
        const userInfo = {};
        userInfo.title = this.user.info.title;
        userInfo.brief = this.user.info.brief;
        userInfo.username = this.user.username;
        userInfo.firstname = this.user.firstname;
        userInfo.lastname = this.user.lastname;
        userInfo.avatar_url = this.user.avatar_url;
        userInfo.email = this.user.email;
        let str = this.user.info.gsm ? this.user.info.gsm : '';
        if (str) {
          const regStr = str.replace(/ /g, "");
          let newval = '';
          regStr.split('').forEach((reg, i) => {
            if (i > 0 && i < 7 && i % 3 === 0) {
              newval = newval.concat(' ');
            } else if (i > 7 && i % 2 === 0) {
              newval = newval.concat(' ');
            }
            newval = newval.concat(regStr[i]);
            str = newval;
          })
        }

        userInfo.gsm = str;
        userInfo.f_t_mode = this.user.info.f_t_mode;
        userInfo.f_t_status = this.user.info.f_t_status;


        this.userInfo = {...userInfo};
        this.tempUserInfo = {...userInfo};
      },
      getSeller() {
        this.api.seller.getSeller()
          .then(({data}) => {
            let result = data;
            if (result.success) {

              const seller = result.data.seller;
              seller.skills = result.data.seller_skills;
              seller.educations = result.data.seller.info.educationHistory;
              let profession = this.professionCategoryArr.find(({ value }) => value === result.data.seller.info.describeYourself)
              if (profession) {
                seller.professionCategory = profession.value;
              }
              delete seller.info;
              this.sellerInfo = {...seller};
              this.tempSellerInfo = {...seller};

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            const requestWaiter = [
              false,
              false,
              false,
              false
            ];
            this.requestWaiter = [...requestWaiter];

          })
          .catch(err => {
            const requestWaiter = [
              false,
              false,
              false,
              false
            ];
            this.requestWaiter = [...requestWaiter];
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      getAllSkills() {
        this.handleRequestWaiter(3, true)
        this.api.general.getAllSkills()
          .then(({ data: resultSkills }) => {
            this.allSkills = resultSkills.data.filter(skill => !!skill.parent_id)
            this.allSkills.forEach((skill) => {
              skill.name = skill.name.tr_TR
            })
            const options = { keys: ['name'], includeMatches: true, ignoreLocation: true, threshold: 0.4, findAllMatches: true }
            const index = Fuse.createIndex(options.keys, this.allSkills)
            this.fuse = new Fuse(this.allSkills, options, index)
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
          })
          .finally(() => {
            this.handleRequestWaiter(3)
          })
      },
      sendVerificationNumber() {
        this.disableVerify = true;
        this.api.user.activateGSM(this.userInfo.gsm.replace(/ /g, ""), this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PHONE_VERIFICATION, info: {gsm: this.userInfo.gsm.replace(/ /g, "")}});
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.disableVerify = false;
          })
          .catch(err => {
            this.disableVerify = false;
            if (err.code !== "ECONNABORTED") {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            }
          });
      },
      updateBasicInfo(sectionId) {
        if (!this.croppedFile) {
          this.api.user.updateUserBasicInfo2(this.tempUserInfo.firstname, this.tempUserInfo.lastname, this.tempUserInfo.title, this.tempUserInfo.brief, this.tempSellerInfo.professionCategory)
            .then(({data}) => {
              const result = data;
              if (result.success) {
                this.$store.commit(this.types.SET_NAME_CHANGES, {firstname: result.data.firstname, lastname: result.data.lastname});
                this.$store.state.user.info.title = result.data.title;
                this.$store.state.user.info.brief = result.data.brief;
                this.sellerInfo.professionCategory = this.tempSellerInfo.professionCategory;
                this.$toasted.global.infoToast({description: result.message});
                this.editableSectionId = null;
                this.croppedFile = null;
                this.setInitialValues();
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
              this.handleRequestWaiter(sectionId);
            })
            .catch(err => {
              console.log(err)
              this.handleRequestWaiter(sectionId);
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        } else {
          const timeStamp = +new Date();
          let azureUploadImg = this.uploadFileToGoogle(this.croppedFile.file, this.Constants.UPLOAD_TYPES.AVATAR, 'avatar', timeStamp);
          let updateUserBasicInfo = this.api.user.updateUserBasicInfo2(this.tempUserInfo.firstname, this.tempUserInfo.lastname, this.tempUserInfo.title, this.tempUserInfo.brief, this.tempSellerInfo.professionCategory);
          Promise.all([updateUserBasicInfo, azureUploadImg])
            .then(res => {
              if (res[0].data.success) {
                this.$store.commit(this.types.SET_NAME_CHANGES, {firstname: res[0].data.data.firstname, lastname: res[0].data.data.lastname});
                this.$store.state.user.info.title = res[0].data.data.title;
                this.$store.state.user.info.brief = res[0].data.data.brief;
                this.sellerInfo.professionCategory = this.tempSellerInfo.professionCategory;
                this.$toasted.global.infoToast({description: res[0].data.message});
                this.setInitialValues();
              } else if (!res[0].data.success) {
                this.$toasted.global.errorToast({description: res[0].data.message});
              }

              if (res[1]) {
                this.$store.state.user.avatar_url = this.Constants.CDN_UPLOADS_G + res[1].uploadName;
                this.userInfo.avatar_url = this.$store.state.user.avatar_url;
                this.tempUserInfo.avatar_url = this.$store.state.user.avatar_url;
                this.$toasted.global.infoToast({description: 'Profil resmi başarıyla değiştirildi'});
              } else {
                this.$toasted.global.errorToast({description: 'Profil resmi değiştirilemedi'});
                this.tempUserInfo.avatar_url = this.$store.state.user.avatar_url;
              }
              this.editableSectionId = null;
              this.croppedFile = null;
              this.handleRequestWaiter(sectionId);
            })
            .catch(err => {
              console.log(err)
              this.handleRequestWaiter(sectionId);
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },
      updateContactInfo() {
        this.api.user.settingsEditGSM(this.tempUserInfo.email, this.tempUserInfo.gsm.replace(/ /g, ""), this.tempUserInfo.f_t_mode, this.tempUserInfo.f_t_status)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (result.data.is_email_change) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHANGE_EMAIL});
              }
              if (result.data.is_gsm_changed) {
                this.$store.state.user.info.gsm = this.tempUserInfo.gsm.replace(/ /g, "");
                this.$store.state.user.info.gsm_is_activated = false;
                this.$store.state.user.info.f_t_status = 2;
              }
              if(result.data.is_line_settings_changed) {
                this.$store.state.user.info.f_t_status = this.tempUserInfo.f_t_status;
                this.$store.state.user.info.f_t_mode = this.tempUserInfo.f_t_mode;
              }
              this.setInitialValues();

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(2);
          })
          .catch(err => {
            this.handleRequestWaiter(2);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      updateSkills(sectionId) {
        this.api.seller.updateSkills2(JSON.stringify(this.tempSellerInfo.skills))
          .then(({data}) => {
            let result = data;

            if (result.success) {
              const newSeller = {...this.sellerInfo};
              newSeller.skills = result.data.seller_skills;
              this.sellerInfo = {...newSeller};
              this.tempSellerInfo = {...newSeller};
              this.$toasted.global.infoToast({description: result.message});
              this.editableSectionId = null;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.handleRequestWaiter(sectionId);
          })
          .catch(err => {
            this.handleRequestWaiter(sectionId);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },
      educationActions(edu, type) {
        if (type === 'edit') {
          this.newEducation = {
            school: edu.school,
            department: edu.department,
            endYear: edu.endYear,
            type: type,
            id: edu.id
          };
          this.newEducationValidation = {...this.newEducation}
          this.educationEditId = edu.id;
          this.editableSectionId = 4;
        } else if (type === 'delete') {
          this.deleteEducationHistory(edu.id)
        }
      },
      addEducation(sectionId) {
        const educationInfo = {...this.newEducation};
        educationInfo.type = this.educationType;
        this.api.seller.createEducationHistory(educationInfo)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              const arr = [...this.sellerInfo.educations];
              arr.push(result.data);
              this.sellerInfo.educations = [...arr];
              this.$toasted.global.infoToast({description: result.message});
              this.resetEducation();
              this.editableSectionId = null;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.handleRequestWaiter(sectionId);
          })
          .catch(err => {
            this.handleRequestWaiter(sectionId);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },
      updateEducation(sectionId) {
        const educationInfo = {...this.newEducation};
        this.api.seller.updateEducationHistory(educationInfo, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              const arr = [...this.sellerInfo.educations];
              arr.forEach(history => {
                if (history.id === this.educationEditId) {
                  history.school = result.data.school;
                  history.department = result.data.department;
                  history.type = result.data.type;
                  history.endYear = result.data.endYear;
                }
              });
              this.sellerInfo.educations = [...arr];
              this.$toasted.global.infoToast({description: result.message});
              this.resetEducation();
              this.editableSectionId = null;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.handleRequestWaiter(sectionId);
          })
          .catch(err => {
            this.handleRequestWaiter(sectionId);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      deleteEducationHistory(id) {
        this.handleRequestWaiter(4, true);
        this.api.seller.deleteEducationHistory(id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.sellerInfo.educations = this.sellerInfo.educations.filter(education => education.id !== id)
              this.$toasted.global.infoToast({description: result.message})
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(4);
          })
          .catch(err => {
            this.handleRequestWaiter(4);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      resetEducation() {
        this.newEducation = {school: '', department: '', endYear: ''};
        this.newEducationValidation = {school: '', department: '', endYear: ''};
        this.educationEditId = null;
        this.educationType = 'education';
      },
      selectEducationType(type) {
        this.resetEducation();
        this.educationType = type;
      },
      editAreaClick(section = null) {
        this.editableSectionId = section;
        this.resetEducation();
      },
      saveButton(sectionId) {
        this.handleRequestWaiter(sectionId, true);
        if (sectionId === 1) this.updateBasicInfo(sectionId);
        else if (sectionId === 2) this.updateContactInfo();
        else if (sectionId === 3) this.updateSkills(sectionId);
        else if (sectionId === 4 && this.educationEditId) this.updateEducation(sectionId);
        else if (sectionId === 4 && !this.educationEditId) this.addEducation(sectionId);
        else if (sectionId === 5) this.deleteAccount();
      },
      cancelButton() {
        const tempUserInfo = {...this.tempUserInfo};
        const userInfo = {...this.userInfo};

        const tempSellerInfo = {...this.tempSellerInfo};
        const sellerInfo = {...this.sellerInfo};
        switch (this.editableSectionId) {
          case 1:
            tempUserInfo.title = userInfo.title;
            tempUserInfo.brief = userInfo.brief;
            tempUserInfo.firstname = userInfo.firstname;
            tempUserInfo.lastname = userInfo.lastname;
            tempUserInfo.avatar_url = userInfo.avatar_url;
            tempSellerInfo.professionCategory = sellerInfo.professionCategory;
            this.croppedFile = null;
            break;

          case 2:
            tempUserInfo.email = userInfo.email;
            tempUserInfo.gsm = userInfo.gsm;
            tempUserInfo.f_t_mode = userInfo.f_t_mode;
            tempUserInfo.f_t_status = userInfo.f_t_status;
            break;
          case 3:
            tempSellerInfo.skills = sellerInfo.skills;
            this.skillSearchTerm = '';
            break;
          case 4:
            this.resetEducation();
            break;
        }
        this.tempUserInfo = {...tempUserInfo};
        this.tempSellerInfo = {...tempSellerInfo};
        this.editableSectionId = null;
      },
      disableCancelButton(sectionId) {
        return this.requestWaiter[sectionId - 1]
      },
      disableSaveButton(sectionId) {
        switch (sectionId) {
          case 1:
            if (this.requestWaiter[sectionId - 1]) return true;
            return (
              this.tempUserInfo.title === this.userInfo.title &&
              this.tempUserInfo.brief === this.userInfo.brief &&
              this.tempUserInfo.firstname === this.userInfo.firstname &&
              this.tempSellerInfo.professionCategory === this.sellerInfo.professionCategory &&
              this.tempUserInfo.avatar_url === this.userInfo.avatar_url &&
              this.tempUserInfo.lastname === this.userInfo.lastname);

          case 2:
            if (this.requestWaiter[sectionId - 1]) return true;
            return (
              (!this.tempUserInfo.email || this.tempUserInfo.email === this.userInfo.email) &&
              (this.tempUserInfo.gsm.replace(/ /g, "") === this.userInfo.gsm.replace(/ /g, "")) &&
              this.tempUserInfo.f_t_mode === this.userInfo.f_t_mode &&
              this.tempUserInfo.f_t_status === this.userInfo.f_t_status);
        }
      },
      handleRequestWaiter(sectionId, boolean = false) {
        const requestWaiter = [...this.requestWaiter];
        requestWaiter[sectionId - 1] = boolean;
        this.requestWaiter = [...requestWaiter];
      },
      openFreelancerLineFaqModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FREELANCER_LING_FAQ});
      },
      selectBoxOnChange(e) {
        const tempSellerInfo = [...this.tempSellerInfo.skills];
        if (e.isSuggestion) tempSellerInfo.push({name: e.value, isSuggestion: true, id: +new Date()});
        else tempSellerInfo.push({name: e.name, isSuggestion: false, id: e.id});
        this.tempSellerInfo.skills = [...tempSellerInfo];
        this.skillSearchTerm = '';
      },
      deleteSkill(skill) {
        this.tempSellerInfo.skills = this.tempSellerInfo.skills.filter(({ id }) => id !== skill.id)
      },
      changeLine() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHANGE_LINE});
      },
      extend() {
        this.handleRequestWaiter(1, true);
        this.api.user.useItem(2, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.api.user.extendLine(this.$Progress)
                .then(({data}) => {
                  let result = data;

                  if (result.success) {
                    this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
                    this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
                    this.$store.state.user.info.f_t_status = 3;
                    this.$toasted.global.errorToast({description: result.message});
                  } else {
                    this.$toasted.global.errorToast({description: result.message});
                  }
                });
              this.handleRequestWaiter(1);
            } else {
              if (result.data && result.data.ok && result.data.ok === 'wait') {
                this.handleRequestWaiter(1);
                this.$toasted.global.errorToast({description: result.message});
              } else {
                const data = {
                  data: {
                    type: "item",
                    total: 12.90,
                    itemId: 2,
                    cards: result.data.cards,
                    walletAmount:result.data.walletAmount
                  }
                };

                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: data, isSinglePay: true}});
                this.handleRequestWaiter(1);
              }
            }
          });
      },
      startLine() {
        this.handleRequestWaiter(2, true);
        this.api.user.startLine(this.user.info.f_t_used, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.state.user.info.f_t_status = result.data.f_t_status;
              this.$store.state.user.info.f_t_used = true;
              this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
              this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
              this.$store.state.user.info.f_t_mode = result.data.f_t_mode;
              this.$store.state.user.info.f_t_line = result.data.f_t_line;
              this.$toasted.global.infoToast({description: result.message});
              this.setInitialValues();
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.handleRequestWaiter(2);

          })
          .catch(err => {
            this.handleRequestWaiter(2);
            if (err.code !== "ECONNABORTED") {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            }
          });

      },
      deleteAccount() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CLOSE_ACCOUNT});
      },
      setImage() {
        document.getElementById('selectedFile').click();
      },
      sendImageToCropModal(e) {
        this.$store.commit(this.types.ACTIVE_MODAL, {
          modalType: this.Modals.CROPPER,
          info: {file: e.target.files[0], canvasPixel: {width: 400, height: 400}, divider: 1.1, viewMode: 1}
        });
        document.getElementById("selectedFile").value = "";
      },
      changeUsername(username) {
        this.changeUsernameLoading = true
        return Api.post('/users/change-username', { username })
          .then(({ data }) => {
            this.pendingUsernameMessage = data.pendingUsernameMessage
            if (data.isUpdatedDirectly) {
              this.$store.state.user.username = data.username
            }
            this.isUsernameEditable = false
            this.$toasted.global.infoToast({description: "İşlem tamam!"})
          })
          .catch(err => {
            if ([400, 403, 404].includes(err.status)) {
              if (err.data.errors.length) {
                return this.$toasted.global.errorToast({ description: err.data.errors[0].msg })
              }
              this.$toasted.global.errorToast({ description: err.data.message })
            } else {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
            }
          })
          .finally(() => {
            this.changeUsernameLoading = false
          })
      }
    },
    computed: {
      endingDate() {
        return customDateFormat(this.$store.state.user.info.f_t_ending_at_timestamp, 'dd MMMM yyyy HH:mm')
      }
    },
    watch: {
      'tempSellerInfo.skills'(newVal) {
        this.disableSkillInput = newVal && newVal.length >= 6;
      },
      skillSearchTerm(newValue) {
        if (newValue.length < 20) {
          this.skillSearchResults = this.fuse.search(newValue.trim()).map(result => result.item)
          this.exactSkillExists = !!this.allSkills.find(skill => skill.name.toLocaleLowerCase('tr') === newValue.trim().toLocaleLowerCase('tr'))
        } else {
          this.skillSearchResults = []
        }
      }
    },
    created() {
      for (let i = 1971; i <= new Date().getFullYear(); i++) {
        this.educationYearArr.push(i)
      }
      if (this.user.seller.isSeller) this.initializeSeller()
      if (!this.user.seller.isSeller) this.requestWaiter = [false, false, true, true]
      this.setInitialValues();

      this.EventBus.$on('cropperImageFile', payload => {
        this.croppedFile = payload;
        let reader = new FileReader();

        reader.onload = (e) => {
	        this.Helper.trackEvents.customEvent("uploaded_avatar", {version:'v1',whereFrom:this.$store.state.componentMap.page,clickedFrom:'settings'});
          this.tempUserInfo.avatar_url = e.target.result;
        };

        reader.readAsDataURL(payload.file);
        // this.tempUserInfo.avatar_url = window.URL.createObjectURL(this.croppedFile.file)
      })

	    this.EventBus.$on('isSinglePayed', (payload) => {
		    if(payload.isSinglePayed) {

			    this.api.user.extendLine(this.$Progress)
				    .then(({data}) => {
					    let result = data;

					    if (result.success) {
						    this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
						    this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
						    this.f_t_status = 3;
						    this.$store.state.user.info.f_t_status = 3;
						    this.$toasted.global.errorToast({description: result.message});

					    } else {
						    this.$toasted.global.errorToast({description: result.message});
					    }

				    });

		    } else {
			    this.$toasted.global.errorToast({description: 'Ödeme Başarısız Oldu'});
		    }
	    })

      this.$nextTick(() => {
        if(this.$route.query.section){
          let elemSection = document.getElementById('sectionID_'+this.$route.query.section);
          if(elemSection){


            if(this.$route.query.open && this.$route.query.open === '1'){
              this.editAreaClick(+this.$route.query.section);
            }
            elemSection.scrollIntoView({
              behavior: 'smooth'
            });
          }

        }
      })
      Api.get('/users/change-username')
        .then(({ data }) => {
          this.pendingUsernameMessage = data.pendingUsernameMessage
        })
    },
    beforeDestroy() {
      this.EventBus.$off("cropperImageFile");
	    this.EventBus.$off('isSinglePayed');
    }
  }
</script>

<style scoped lang="scss">

.hint-skill-icon /deep/ {
  width: 16px;
  height: 16px;
  margin-left: 5px;

  g {
    fill: #5e6b79;
  }
}


  .settings-container {
    display: flex;
    min-height: calc(100vh - 111px);
    width: 1140px !important;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
  }

  .content-container {
    box-sizing: border-box;
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 340px;
    z-index: 1;
    background-color: #f4f5f7;
    width: 100%;
    min-height: calc(100vh - 111px);
  }

  .grey-background {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    min-height: calc(100vh);
    background-color: #f4f5f7;
  }

  .section {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .section-header-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;

    .edit-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .edit-text {
        font-size: 14px;
        font-weight: 400;
        color: #8b95a1;
        margin-left: 5px;
      }
    }
  }

  .section-header {
    font-size: 24px;
    font-weight: 400;
    color: #4b4f52;

    span {
      font-weight: 600;
    }
  }

  .section-body {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #eaedf2;
    background-color: #fff;
    min-width: 100%;
    padding: 34px 40px;
  }

  .separator {
    border-bottom: 1px solid #eaedf2;
    width: 100%;
  }

  .add-dash-button {
    box-sizing: border-box;
    border: 1px dashed #bfc8d2;
    background-color: #fff;
    padding: 0 10px 0 10px;
    border-radius: 4px;
    max-width: 100%;
    min-width: 66px;
    height: 30px;
    color: #8b95a1;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;

    &:hover {
      background-color: #fff !important;
    }
  }

  .save-cancel-body {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  /*user-info*/
  .user-info-section {
    display: flex;
    flex-direction: row;
  }

  .user-avatar {
    width: 84px;
    height: 84px;
    position: relative;
  }

  .user-avatar-img {
    box-sizing: border-box;
    border: 2px solid #eaeaea;
    border-radius: 100%;
    width: 84px;
    height: 84px;
  }

  .add-image {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #26ca9a;
    color: #fff;
    position: absolute;
    right: -1px;
    top: -6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar-add-img /deep/ {
      width: 16px;
      height: 16px;

      path {
        fill: #fff;
      }
    }
  }

  .user-info {
    margin-left: 26px;
    width: 100%;
  }

  .username-text {
    font-size: 26px;
    font-weight: 600;
    color: #4b4f52;
    margin-bottom: 5px;
  }

  .user-fistname-lastname-text {
    font-size: 18px;
    font-weight: 400;
    color: #5e6b79;
    margin-bottom: 14px;
    text-transform: capitalize;
  }

  .profession-text {
    font-size: 18px;
    font-weight: 600;
    color: #26ca9a;
    margin-top: 14px;
    margin-bottom: 10px;
  }

  .profession-description-area {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .profession-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #8b95a1;
    word-break: break-word;
  }

  /*contact info*/

  .contact-info {
    display: flex;
    flex-direction: column;
  }

  .contact-item {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;

    .left {
      width: 25%;
      align-self: center;
    }

    .right {
      width: 75%;
    }
  }

  .contact-label {
    font-size: 16px;
    color: #8b95a1;
    font-weight: 400;
    margin: 0;
  }

  .contact-text {
    font-size: 16px;
    color: #2d3640;
    font-weight: 400;
    min-width: 173px;
  }

  .gsm {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .gsm-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #fd4056;
    width: 93px;
    height: 28px;
    color: #fd4056;
    font-size: 13px;
    font-weight: 400;
  }

  .gsm-active {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #26ca9a;
    padding: 0 10px 0 10px;
    width: 80px;
    height: 28px;
    color: #26ca9a;
    font-size: 13px;
    font-weight: 400;
  }

  .freelancer-gsm {
    display: flex;
    flex-direction: column;
  }

  .freelancer-gsm-item {
    display: flex;
    align-items: center;
    height: 30px;

    .freelancer-gsm-item-label {
      font-weight: 300;
      font-size: 14px;
      width: 160px;
      color: #5e6b79;
    }

    .freelancer-gsm-item-text {
      color: #2d3640;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .f-t-status-badge-active {
      width: 80px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      background-color: #26ca9a;
      color: #fff;
    }

    .f-t-status-badge-passive {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      width: 80px;
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      background-color: #fd4056;
      color: #fff;
    }
  }

  .freelancer-gsm-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
    color: #5e6b79;

    .red {
      font-weight: 400;
      font-style: italic;
      color: #fd4056;
    }

    .dark {
      font-weight: 800;
      color: #2d3640;
    }

    .green {
      font-weight: 800;
      color: #00a575;
    }
  }

  .change-f_t_line {
    background-color: #eaedf2;
    width: 215px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #8b95a1;

    span {
      font-weight: 800;
      color: #2d3640;
      margin-left: 3px;
    }
  }

  /*skills*/

  .skill-body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .skill-item-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .skill-item {
    border: 1px solid #26ca9a;
    border-radius: 4px;
    padding: 7px 12px;
    color: #26ca9a;
    font-size: 13px;
    font-weight: 400;
    margin-right: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .skill-item-sugessted {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 12px 5px 31px;
    line-height: 20px;
    font-size: 14px;
    color: #5e6b79;
    font-weight: 400;
    margin-right: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
    border: 1px solid #e5e5e5;
  }

  .editable-skills-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .editable-skill-item {
    cursor: pointer;
    position: relative;
    background-color: #eaedf2;
    border-radius: 4px;
    padding: 5px 28px 5px 10px;
    line-height: 20px;
    font-size: 14px;
    color: #2d3640;
    font-weight: 400;
    margin-right: 25px;
    margin-top: 30px;
    border: 1px solid #eaedf2;


    &:hover {
      background-color: #26ca9a;
      color: #fff;
      border: 1px solid #26ca9a;

      .delete-skill-icon /deep/ {
        #ic_close_black {
          fill: #fff;
        }
      }

    }
  }

  .suggestion-skill-item {
    cursor: pointer;
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px 28px 5px 31px;
    line-height: 20px;
    font-size: 14px;
    color: #5e6b79;
    font-weight: 400;
    margin-right: 25px;
    margin-top: 30px;
    border: 1px solid #e5e5e5;

    &:after {
      pointer-events: none;
    }

    &:before {
      pointer-events: none;
    }

    span {
      color: #8b95a1;
    }
  }

  .delete-skill-icon /deep/ {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;

    #ic_close_black {
      fill: #bfc8d2;
    }
  }

  .suggestion-skill-icon /deep/ {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;

    path {
      fill: #26ca9a;
    }
  }

  .hint-skill-icon /deep/ {
    width: 20px;
    height: 20px;

    g {
      fill: #5e6b79;
    }
  }

  .hint-body {
    position: relative;
    display: flex;
    align-items: center;
    cursor: help;
    margin-left: 75px;

    p {
      margin-left: 5px;
      font-weight: 400;
      font-size: 20px;
      color: #4b4f52;

      span {
        font-weight: 600;
      }
    }

    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .tooltiptext {
    display: block;
    visibility: hidden;
    position: absolute;
    width: 270px;
    background-color: #2d3640;
    color: #fff;
    text-align: center;
    padding: 5px 3px;
    border-radius: 6px;
    z-index: 1;
    left: 0;
    top: 36px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  .tooltiptext::after {
    content: " ";
    position: absolute;
    left: 15px;
    bottom: 100%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #2d3640 transparent;
  }


  /*education*/


  .seller-type-body {
    box-sizing: border-box;
    padding: 20px 57px 20px 57px;
    border: 1px solid #eaedf2;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
  }

  .type-header {
    font-size: 16px;
    font-weight: 400;
    color: #8b95a1;
  }

  .type-radio-body {
    display: flex;
    align-items: center;
    margin-left: 50px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #fff;

    &:hover {
      background: none !important;
    }

    &:disabled {
      background: none !important;
      opacity: 0.4;
    }
  }

  .type-radio-body-button {
    box-sizing: border-box;
    border: 1px solid #8b95a1;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    position: relative;
  }

  .selected-radio-mask {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }

  .type-radio-text {
    font-size: 16px;
    color: #2d3640;
    font-weight: 400;
    margin-left: 8px;
  }

  /*education*/

  .education-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .education-item {
    box-sizing: border-box;
    width: 392px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    padding-left: 10px;
    padding-right: 11px;
    margin-bottom: 7px;
    margin-top: 7px;

    &:nth-child(2n) {
      margin-left: 23px;
    }

    .education-school {
      font-size: 14px;
      line-height: 18px;
      color: #5e6b79;
      font-weight: 400;
      max-width: 140px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .education-department {
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      color: #8b95a1;
      font-weight: 400;
      margin-left: 3px;
      max-width: 140px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .education-endYear {
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      color: #8b95a1;
      font-weight: 400;
      margin-left: 3px;
    }
  }

  .logout-img /deep/ {
    width: 14px;
    height: 16px;
    margin-right: 6px;

    path {
      fill: #8b95a1;
    }
  }

  .gsm-active-img /deep/ {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-bottom: -2px;

    path {
      fill: #26ca9a;
    }
  }

  .add-icon /deep/ {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-bottom: -2px;

    path {
      fill: #8b95a1;
    }
  }

</style>
